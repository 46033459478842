import { render, staticRenderFns } from "./CustomEdit.vue?vue&type=template&id=36714930&scoped=true&"
import script from "./CustomEdit.vue?vue&type=script&lang=js&"
export * from "./CustomEdit.vue?vue&type=script&lang=js&"
import style0 from "./CustomEdit.vue?vue&type=style&index=0&id=36714930&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36714930",
  null
  
)

export default component.exports
<template>
  <div id="CustomEdit"> 
    <Header/>  

    <body class="bg-white" style="overflow: hidden;">
      <!-- eslint-disable vue/no-v-html -->
      <div class="ck-content">
        <div v-html="description"></div>  
      </div>
      <!--eslint-enable-->
    </body>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import { oltConsole , oAC } from "@/lib/oltModule/initClient";
import { config } from "@/include/config"; 

export default {
    name: 'CustomEdit',
    components: {
        Header,
    },
    data () {
      return { 
        data: "",
        description: "",
        ptid: "",
        height: 0,
      }
    },
    watch: {
      height: function(){
        this.$JsBridge.callHandler('viewHeight', `${this.height}`, this.viewHeightCallback);
      }
    },
    methods: {
      viewHeightCallback(){},
      checkHeight(){
        this.$nextTick(() => {
          var body = document.body,
          html = document.documentElement;
          this.height = Math.max( body.scrollHeight, body.offsetHeight, 
                  html.clientHeight, html.scrollHeight, html.offsetHeight );
        }); 
      },
      getApi(){
        this.apiName = "page_deep_learn_detail";
        oAC.getAllRecord(oAC.getApiParam(this), {ptid:this.ptid}).then(res => {
          if (res.product && res.product.length > 0) {
            if (res.product[0] && res.product[0].description) {
              this.description = res.product[0].description;
              this.$nextTick(() => {
                var body = document.body,
                html = document.documentElement;
                this.height = Math.max( body.scrollHeight, body.offsetHeight, 
                       html.clientHeight, html.scrollHeight, html.offsetHeight );
                this.$JsBridge.callHandler('viewHeight', `${this.height}`, this.viewHeightCallback);
                setTimeout(this.checkHeight, 300);  
              }); 
            }
          }
        }).catch((err)=> {
          oltConsole.log(err);
        });

      },
      openWebVue(url){
        this.$JsBridge.callHandler('openWebVue',url, this.openWebVueCallback);
      },
      openWebVueCallback(){
      },
    },
    mounted() {
      window.openWebVue = this.openWebVue;
    },
    created(){
      if (this.$route.query.ptid != undefined) {
        this.ptid = this.$route.query.ptid;
      }
      this.getApi();
      window["openWeb"] = this.openWebVue;
    }
};
</script>
<style scoped>
  @import '../../../../public/assets/css/ckeditor5-content-styles.css';
  @import '../../../../public/assets/css/ckeditor5-style.css';
</style>